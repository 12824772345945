<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Profil de utilizator">
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <!-- Latest Customers -->
        <b-col xl="6">
          <base-block title="Date personale" header-bg content-full>
            <template #options>
              <router-link class="dropdown-item d-flex align-items-center justify-content-between" to="/user/setting">
                <span>Setari </span> <i class="si si-settings ml-2"></i>
              </router-link>
            </template>
            <b-row class="row-deck">
              <b-col xl="3" class="text-right">
                <avatar v-if="meUser != null" v-bind:fullname="meUser.name" :size="150" :radius="50" color="#5c80d1"></avatar>
              </b-col>
              <b-col xl="9">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <p><strong>Prenume:</strong> <span v-if="meUser != null" v-text="meUser.firstName" class="text-primary"></span></p>
                    <p><strong>Nume:</strong> <span v-if="meUser != null" v-text="meUser.lastName" class="text-primary"></span></p>
                    <p><strong>Adresa de email:</strong> <span v-if="meUser != null" v-text="meUser.email != null ? meUser.email : 'nu este specificat'" class="text-primary"></span></p>
                    <p>&nbsp;</p>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <!-- END Latest Customers -->

        <!-- Latest Orders -->
        <b-col xl="6">
          <base-block title="Date companie" header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="6">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <p><strong>Denumire:</strong> <span v-if="meUser != null && meUser.companyHeadquarter" v-text="meUser.companyHeadquarter.name" class="text-primary"></span></p>
                    <p><strong>Tara:</strong> <span v-if="meUser != null && meUser.companyHeadquarter" v-text="meUser.companyHeadquarter.country" class="text-primary"></span></p>
                    <p><strong>Judet:</strong> <span v-if="meUser != null && meUser.companyHeadquarter" v-text="meUser.companyHeadquarter.county" class="text-primary"></span></p>
                    <p><strong>Localitate:</strong> <span v-if="meUser != null && meUser.companyHeadquarter" v-text="meUser.companyHeadquarter.locality" class="text-primary"></span></p>
                  </div>
                </base-block>
              </b-col>
              <b-col xl="6">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <p><strong>CUI:</strong> <span v-if="meUser != null && meUser.company" v-text="meUser.company.fiscalCode" class="text-primary"></span></p>
                    <p><strong>Numarul de intregistrare:</strong> <span v-if="meUser != null && meUser.company" v-text="meUser.company.registrationNumber" class="text-primary"></span></p>
                    <p><strong>Numar de telefon:</strong> <span v-if="meUser != null && meUser.companyHeadquarter" v-text="meUser.companyHeadquarter.phone" class="text-primary"></span></p>
                    <p><strong>Adresa de email:</strong> <span v-if="meUser != null && meUser.companyHeadquarter" v-text="meUser.companyHeadquarter.email" class="text-primary"></span></p>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <!-- END Latest Orders -->
      </b-row>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component';
import {ME_USER_FOR_PROFILE} from "@/constants/user-graphql";

export default {
  name: "Profile",
  data () {
    return {
      meUser: null,
    }
  },
  components: {Avatar},
  apollo: {
    meUser: {
      query: ME_USER_FOR_PROFILE,
      fetchPolicy: 'no-cache'
    }
  }
}
</script>

<style scoped>

</style>